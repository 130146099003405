// const server = `http://localhost:6969`;
const server = `https://review-guru-server.onrender.com`;

// const server=   `https://incandescent-lebkuchen-d5a33e.netlify.app`

const apiList = {
    allcompanies: `${server}/api/getAllCompanies`,
    individualCompany: `${server}/api/individualCompany`,
    login:`${server}/auth/login`,
    sendReview:`${server}/api/review`,
    allcategories:`${server}/api/alltypes`,
    addcompany:`${server}/api/addCompany`,
    companyType:`${server}/api/companyType`,
    addcategory:`${server}/api/addCategoryType`,
    signup:`${server}/api/addUser`,
    likes:`${server}/api/like`,
    report:`${server}/api/report`,
    allusers:`${server}/api/allusers`,
    deletereview:`${server}/api/reviews`,
    sendotp:`${server}/api/send-otp`,
    otpverify:`${server}/api/verify-otp`,
    changepassword:`${server}/api/changepassword`
}


export default apiList;