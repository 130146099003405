import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiList from '../lib/apilist';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Otpverification() {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [otpSent, setOtpSent] = useState(false); // Track whether OTP has been sent

    const navigate = useNavigate();

    const handleVerify = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(apiList.sendotp, {
                userMail: email,
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                setOtpSent(true); // Set OTP sent flag to true
                toast.success("OTP sent successfully");
            } else {
                toast.error("Failed to send OTP. Please check your email and try again.");
            }
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong. Please try again later.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${apiList.otpverify}/${email}`, {
                enteredOtp: otp
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                const data = response.data;
                console.log(data);
                toast.success("OTP Verified successfully");
                setTimeout(() => {
                    navigate('/changepassword', { state: email })
                }, 2000);
            } else {
                const errorData = response.data;
                console.error(errorData);
                toast.error("Invalid OTP");
            }
        } catch (error) {
            console.error("Error:", error);

            if (error.response) {
                console.error("Server responded with:", error.response.data);
                toast.error("Server error. Please try again later.");
            } else if (error.request) {
                console.error("No response received from server");
                toast.error("No response from the server. Please try again later.");
            } else {
                console.error("Error setting up the request:", error.message);
                toast.error("Something went wrong. Please try again later.");
            }
        }
    };

    const handleButtonClick = otpSent ? handleSubmit : handleVerify;
    const buttonLabel = otpSent ? "Submit" : "Verify Mail";

    

    return (
        <div>
            <div className="">
                <ToastContainer />
                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100">
                            <div className="login100-form-title" style={{ backgroundImage: "url()" }}>
                                <span className="login100-form-title-1" style={{ fontSize: "18px", textAlign: "start" }}>
                                    OTP Verification
                                </span>
                            </div>

                            <form className="login100-form validate-form" onSubmit={handleButtonClick}>
                                {/* Email input */}
                                <div className="wrap-input100 validate-input mb-3" data-validate="Username is required">
                                    <span className="label-input100">Email</span>
                                    <div className='d-flex'>
                                        <input className="input100" type="text" name="username" value={email}
                                            onChange={(e) => setEmail(e.target.value)} placeholder="Enter mail" />
                                        
                                    </div>
                                    <span className="focus-input100"></span>
                                </div>

                                {/* OTP input */}
                                {otpSent && (
                                    <div className="wrap-input100 validate-input m-b-26" data-validate="Username is required">
                                        <span className="label-input100">OTP</span>
                                        <input className="input100" type="text" name="username" value={otp}
                                            onChange={(e) => setOtp(e.target.value)} placeholder="Enter otp sent to your mail" />
                                        <span className="focus-input100"></span>
                                    </div>
                                )}

                                <div className="container-login100-form-btn">
                                    {/* Single button for both Verify and Submit */}
                                    <button className="login100-form-btn mt-5" type="submit">
                                        {buttonLabel}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Otpverification;
