import React, { useEffect, useState } from 'react';
import Footer from '../components/footer';
import Usernavbar from "../components/userNavbar";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Contactus() {
    const navigate = useNavigate();



    const isTokenValid = () => {
        const jwtToken = Cookies.get('token');

        // Check if the token exists and is not expired
        return jwtToken ? true : false;
    };


    useEffect(() => {
        // Check if the token is not valid, then redirect to the login page
        if (!isTokenValid()) {
            navigate('/login');
        }
    }, []);



    const [fullName, setFullName] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");


    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            fullName: fullName,
            contactNumber: contactNumber,
            email: email,
            subject: subject,
            message: message
        }
        axios.post('https://sheet.best/api/sheets/cf4ee283-8302-4706-a7eb-d4810d61c9a7', data)
            .then((response) => {
                console.log(response)
                setFullName("");
                setContactNumber("");
                setEmail("");
                setSubject("");
                setMessage("");
                toast.success('Message sent successfully!');

            })
    }

    return (
        <div>
            <ToastContainer position="top-center" autoClose={1000} />
            <Usernavbar />
            <div style={{ backgroundColor: "#f5f5f5" }}>
                {/* <div class="contact-container container  pb-5" style={{ paddingTop: "130px" }}>
                    <div className='row'>
                        <div className='col-lg-6 d-flex align-items-center'>
                            <div className='d-none d-lg-block'>
                                <img src='https://img.freepik.com/premium-photo/words-with-contact-us-business-concept-idea_352439-1250.jpg?w=1380' className='img-fluid contactImg'></img>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <form class="contact-form">
                                <h2 className='d-lg-none'>Contact Us</h2>
                                <input type="text" placeholder="Your Name" className='form-control' required />
                                <input type="tel" placeholder="Phone Number" className='form-control' required />
                                <input type="email" placeholder="Your Email" className='form-control' required />
                                <input type="text" placeholder="Subject" className='form-control' required />
                                <textarea placeholder="Message" rows={5} className='form-control' required></textarea>
                                <div style={{ textAlign: "end" }}>
                                    <button type="submit">Send Message</button>
                                </div>

                            </form>
                            <p style={{ fontSize: "12px", color: "#666666" }}>
                                By submitting this form, I acknowledge and consent to receiving promotional updates from Review Insight. I also agree to the processing of my personal information in compliance with the privacy policy, ensuring the secure handling of my data for the purpose of enhancing my experience on the review site.                            </p>
                        </div>
                    </div>



                </div> */}
                <div className='container contact-us pb-5' style={{ paddingTop: "130px" }}>
                    <div className='row'>
                        <div className='col-md-6 d-flex align-items-center'>

                            <div class="contact-info ">
                                <div className='d-flex justify-content-center'>
                                    <img src='https://img.freepik.com/free-photo/customer-service-business-contact-concept-wooden-cube-block-which-print-screen-letter-telephone-email-address-message_616485-45.jpg?w=996&t=st=1705661965~exp=1705662565~hmac=ff3aaa19faa3b62952e72b9927d40405a6d95104fc29936d76fff3da0bbcdc9a' className='img-fluid mb-3' style={{ height: "300px", borderRadius: "20px" }}></img>
                                </div>
                                <h2 className='aboutheading1 text-center'>Thank you for visiting Review guru!</h2>
                                <p className='aboutPara'>If you are a current Review Guru user who is utilizing our review platform or considering our services, and you have any inquiries or feedback about our services, please feel free to reach out to us. Your questions and comments are important, and we are here to assist you in enhancing your experience with Review Guru.</p>
                                <p></p>
                                <p className='aboutPara'>For more details regarding any of our review site services, please complete the contact form, and we will promptly get back to you. Your inquiry is important to us, and we look forward to providing you with the information you need about Review Guru.</p>
                                {/* <p className='aboutHeaing1'>Corporate Office:</p>
                                <p className='aboutPara'>Review Guru</p>
                                <p className='aboutPara'> 3rd Floor, Akshaya Arcade</p>
                                <p className='aboutPara'>Kavuri Hills Phase 2</p>
                                <p className='aboutPara'>Hyderabad, Telangana</p>
                                <p className='aboutPara'>India - 500033.</p>
                                <p className='aboutPara'>Email: info@reviewguru.com</p>
                                <p className='aboutPara'><a href="#">Join Our Telegram Group</a></p>
                                <p className='aboutPara'><a href="#">Connect With us on LinkedIn</a></p> */}
                            </div>
                        </div>
                        <div className='col-md-6'>

                            <div class="contact-form">
                                <form className='contactform' onSubmit={handleSubmit}>
                                    <label className='contactLabel' for="name">Full Name</label>
                                    <input type="text" id="name" name="name" className='form-control contactip' onChange={(e) => setFullName(e.target.value)} value={fullName} required />
                                    <label className='contactLabel' for="contact-number">Contact Number</label>
                                    <input type="tel" id="contact-number" name="contact-number" className='form-control contactip' onChange={(e) => setContactNumber(e.target.value)} value={contactNumber} required />
                                    <label className='contactLabel' for="email">Email</label>
                                    <input type="email" id="email" name="email" className='form-control contactip' onChange={(e) => setEmail(e.target.value)} value={email} required />
                                    <label className='contactLabel' for="subject">Subject</label>
                                    <input type="text" id="subject" name="subject" className='form-control contactip' onChange={(e) => setSubject(e.target.value)} value={subject} required />
                                    <label className='contactLabel' for="message">Message</label>
                                    <textarea id="message" name="message" rows={5} className='form-control contactip' onChange={(e) => setMessage(e.target.value)} value={message} required></textarea>
                                    <div className='d-flex justify-content-end'>
                                        <button className='contactBtn mt-2' type="submit">Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>





            </div>
            <Footer />
        </div>
    )
}

export default Contactus
