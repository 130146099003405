import React, { useState } from "react";
import loginlogo from "../images/reviewguulogoremovebg-preview.png"
import { useNavigate, Link as RouterLink } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import axios from "axios";
import apiList from "../lib/apilist";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';


function Auth() {

    const navigate = useNavigate()
    const gotoLogin = () => {
        navigate('/login');
    }

    const [userName, setUserName] = useState("")
    const [employeeId, setEmployeeID] = useState("");
    const [designation, setDesignation] = useState("");
    const [company, setCompany] = useState("");
    const [userMail, setUserMail] = useState("");
    const [passWord, setPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [showPassword, setShowPassword] = useState(false);



    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!userName || !designation || !company || !userMail || !passWord || !confirmpassword  || !phoneNumber) {
            toast.error("Please fill in all the fields.", "error");
            return;
        }

        if (passWord !== confirmpassword) {
            toast.error("Password and Confirm Password do not match.", "error");
            return;
        }

        try {
            const response = await axios.post(apiList.signup, {
                userName: userName,
                designation:designation,
                company:company,
                userMail,
                password: passWord,
                phoneNumber,
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                toast.success("Signup successful.", "success");
                navigate("/login");
            } else {
                toast.error(`Signup failed: ${response.data}`, "error");
            }
        } catch (error) {
            toast.error(`Error during signup: ${error.message}`, "error");
        }
    };


    return (
        <>
            <ToastContainer />
            {/* <div className="container">
                <div className="row">
                    <div className="col-lg-6 loginCard">
                        <div className="card p-3 mt-5 logincardstyle w-100">

                            <Typography component="h1" variant="h5">
                                Sign up
                            </Typography>

                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="userName"
                                            required
                                            fullWidth
                                            id="FirstName"
                                            label="First Name"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="userName"
                                            required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            autoFocus
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            value={userMail}
                                            onChange={(e) => setUserMail(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="PhoneNumber"
                                            label="Phone Number"
                                            name="PhoneNumber"
                                            autoComplete="PhoneNumber"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                            value={passWord}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="password"
                                            label="Confirm Password"
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                            value={confirmpassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                    </Grid>

                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Sign Up
                                </Button>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <MuiLink component={RouterLink} variant="body2" style={{ color: "black", textDecoration: "none" }} to="/">
                                            Already have an account? Login
                                        </MuiLink>
                                    </Grid>
                                </Grid>
                            </Box>



                        </div>
                    </div>
                    <div className="col-lg-6 d-none d-lg-block">
                        <div className="text-center" style={{ marginTop: "180px" }}>
                            <img src="https://static.vecteezy.com/system/resources/previews/003/689/230/original/online-registration-or-sign-up-login-for-account-on-smartphone-app-user-interface-with-secure-password-mobile-application-for-ui-web-banner-access-cartoon-people-illustration-vector.jpg" className="img-fluid text-center imageAnimation" style={{ width: "600px" }}></img>
                        </div>
                    </div>

                </div>
            </div> */}
            <div class="limiter">
                <div class="container-login100">
                    <div class="wrap-login100">
                        <div class="login100-form-title signupImage" >
                            <span class="login100-form-title-1">
                                Sign Up
                            </span>
                        </div>

                        <form class="login100-form validate-form" onSubmit={handleSubmit}>
                            <div class="wrap-input100 validate-input m-b-26" data-validate="Username is required">
                                <span class="label-input100">Name</span>
                                <input class="input100" type="text" name="username" value={userName}
                                    onChange={(e) => setUserName(e.target.value)} />
                                <span class="focus-input100"></span>
                            </div>
                            <div class="wrap-input100 validate-input m-b-26 mt-2" data-validate="Username is required">
                                <span class="label-input100">Designation</span>
                                <input class="input100" type="text" name="designation" value={designation}
                                onChange={(e) => setDesignation(e.target.value)}
                                />
                                <span class="focus-input100"></span>
                            </div>
                            <div class="wrap-input100 validate-input m-b-26 mt-2" data-validate="Username is required">
                                <span class="label-input100">Company</span>
                                <input class="input100" type="text" name="Company" value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                />
                                <span class="focus-input100"></span>
                            </div>
                            <div class="wrap-input100 validate-input m-b-26 mt-2" data-validate="Username is required">
                                <span class="label-input100">Email</span>
                                <input class="input100" type="text" name="username" value={userMail}
                                    onChange={(e) => setUserMail(e.target.value)} />
                                <span class="focus-input100"></span>
                            </div>
                            <div class="wrap-input100 validate-input m-b-26 mt-2" data-validate="Username is required">
                                <span class="label-input100">Mobile</span>
                                <input class="input100" type="text" name="username" value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)} />
                                <span class="focus-input100"></span>
                            </div>


                            <div class="wrap-input100 validate-input m-b-18 mt-2" data-validate="Password is required">
                                <span class="label-input100">Password</span>
                                <div class="password-input-container d-flex">
                                    <input
                                        class="input100"
                                        type={showPassword ? "text" : "password"}
                                        name="pass"
                                        value={passWord}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter password"

                                    />
                                    <span
                                        class="toggle-password-icon"
                                        style={{marginTop:"15px"}}
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                                    </span>

                                </div>

                                <span class="focus-input100"></span>
                            </div>
                            <div class="wrap-input100 validate-input m-b-18 mt-2" data-validate="Password is required">
                                <div class="input-container">
                                    <span class="label-input100">Confirm</span>
                                    <div class="password-input-container d-flex">
                                        <input
                                            class="input100"
                                            type={showPassword ? "text" : "password"}
                                            name="pass"
                                            value={confirmpassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            placeholder="Confirm password"
                                        />
                                        <span 
                                            class="toggle-password-icon"
                                            style={{marginTop:"15px"}}
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                                        </span>
                                    </div>
                                    <span class="focus-input100"></span>
                                </div>
                            </div>

                            <div class="flex-sb-m w-full p-b-30 fourthDiv">
                                <div class="contact100-form-checkbox">
                                    <span className="aboutPara">Already Registred User? </span>
                                    <MuiLink to="/login" component={RouterLink} variant="body2" style={{ color: "#57b846", textDecoration: "none",fontWeight:"600",fontSize:"16px" }}>
                                        {"Sign In"}
                                    </MuiLink>
                                </div>
                            </div>
                            <div class="container-login100-form-btn">
                                <button class="login100-form-btn mt-3">
                                    SIGN UP
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Auth