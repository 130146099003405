import React, { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import loginlogo from "../images/reviewguulogoremovebg-preview.png";
import axios from "axios";
import apiList from "../lib/apilist";
import Cookies from "js-cookie";

import MuiLink from '@mui/material/Link';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';


function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(apiList.login, {
                userMail: email,
                password: password,
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            console.log(response.data);

            if (response.status >= 200 && response.status < 300) {

                toast.success("Login successful.", { position: toast.POSITION.TOP_CENTER });

                if (email === "hr@gmail.com") {
                    Cookies.set("admintoken", response.data.admintoken);
                    Cookies.set("userName", response.data.userName);
                    Cookies.set("designation", response.data.designation)
                    console.log("Login successful:", response.data);
                    navigate("/adminhome");
                }
                else {
                    Cookies.set("token", response.data.token);
                    Cookies.set("userName", response.data.userName);
                    Cookies.set("designation", response.data.designation)
                    console.log("Login successful:", response.data);
                    navigate("/userhome");
                }

            } else {
                console.error("Login failed:", response.data);
                toast.error("Login failed. Please check your credentials.", { position: toast.POSITION.TOP_CENTER });
            }
        } catch (error) {
            console.error("Error during login:", error.message);
            toast.error("Error during login. Please try again later.", { position: toast.POSITION.TOP_CENTER });
        }
    };

    const Jwttoken = Cookies.get("token");
    const admintoken = Cookies.get("admintoken");
    console.log(admintoken)

    useEffect(() => {
        if (Jwttoken) {
            navigate("/userhome")
        }
        if (admintoken) {
            navigate("/adminhome")
        }
    }, [])


    return (

        <div className="">

            <ToastContainer />
            <div class="limiter">
                <div class="container-login100">
                    <div class="wrap-login100">
                        <div class="login100-form-title" style={{ backgroundImage: "url()" }}>
                            <span class="login100-form-title-1">
                                Sign In
                            </span>
                        </div>

                        <form class="login100-form validate-form" onSubmit={handleLogin}>
                            <div class="wrap-input100 validate-input m-b-26" data-validate="Username is required">
                                <span class="label-input100">Email</span>
                                <input class="input100" type="text" name="username" value={email}
                                    onChange={(e) => setEmail(e.target.value)} placeholder="Enter Email" />
                                <span class="focus-input100"></span>
                            </div>

                            <div class="wrap-input100 validate-input m-b-18 mt-3" data-validate="Password is required">
                                <span class="label-input100">Password</span>
                                <div class="password-input-container d-flex">
                                    <input
                                        class="input100"
                                        type={showPassword ? "text" : "password"}
                                        name="pass"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter password"

                                    />
                                    <span
                                        class="toggle-password-icon"
                                        style={{ marginTop: "15px" }}
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                                    </span>

                                </div>

                                <span class="focus-input100"></span>
                            </div>

                            <div class="flex-sb-m w-full p-b-30 fourthDiv">
                                <div class="contact100-form-checkbox">
                                    <span className="aboutPara">Don't have an account? </span>
                                    <MuiLink to="/signup" component={RouterLink} variant="body2" style={{ color: "#57b846", textDecoration: "none", fontWeight: "600", fontSize: "16px" }}>
                                        {"Sign Up"}
                                    </MuiLink>
                                </div>

                                <div>
                                    <MuiLink to="/otpverify" component={RouterLink} variant="body2" class="txt1">
                                        Forgot Password?
                                    </MuiLink>
                                </div>
                            </div>

                            <div class="container-login100-form-btn">
                                <button class="login100-form-btn mt-3">
                                    Login
                                </button>
                            </div>
                            <div className="mt-3">
                                <i style={{ color: "red", fontSize: "25px" }} class="ri-error-warning-fill"></i>
                                <span className="ml-2" style={{ fontFamily: "Roboto,HelveticaArial,sans-serif", fontWeight: "400", letterSpacing: "0.01071em", fontSize: "14px" }}>All Your information is safeguarded and secure.</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
