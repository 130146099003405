import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Navbar from '../Admin/navbar';
import Footer from '../components/footer';
import apiList from '../lib/apilist';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Adminfooter from './adminfooter';

function Addingcompany() {


    const [companyName, setCompanyName] = useState("");
    const [companyType, SetCompanyType] = useState("");
    const [Location, setLocation] = useState("");
    const [companyDes, SetCompanyDes] = useState("");
    const [logo, SetLogo] = useState("");

    const token = Cookies.get("admintoken");


    const [categories, setCategories] = useState([]);


    const fetchData = async () => {
        try {
            const response = await axios.get(apiList.allcategories, {
                headers:{
                    token : token
                }
            });
            console.log(response.data);
            if (response.status === 200) {
                setCategories(response.data);
            } else {
                console.error("Failed to fetch category names");
            }
        } catch (error) {
            console.error("Error fetching category names:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const initialFormData = {
        companyName: companyName,
        companyType: companyType,
        Location: Location,
        companyDes: companyDes,
        logo: logo
    };



    const handleSubmit = async (e) => {

        e.preventDefault();



        try {
            const response = await Axios.post(apiList.addcompany, initialFormData);


            toast.success('Company added successfully');
            setCompanyName("")
            SetCompanyDes("");
            setLocation("");
            SetLogo("");
            SetCompanyType("");

        } catch (error) {
            console.error('Error submitting the form:', error);
            toast.error('Error adding company');
        }
    };

    const navigate = useNavigate();

    const isTokenValid = () => {
        const jwtToken = Cookies.get('admintoken');
  
        // Check if the token exists and is not expired
        return jwtToken ? true : false;
    };
  
  
    useEffect(() => {
        // Check if the token is not valid, then redirect to the login page
        if (!isTokenValid()) {
            navigate('/login');
        }
    }, []);
  

    return (
        <div>
            <Navbar />
            <div className="container addcpmanyContainer col-12">
                <div className="header">
                    {/* <h2>Add Company</h2> */}
                </div>
                <div className="main">
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <label htmlFor="name">Company Name</label>
                                <input
                                    type="text"
                                    placeholder="Company Name"
                                    name="companyName"
                                    id="name"
                                    className='form-control iptag p-3'
                                    required
                                    value={companyName}
                                    autoComplete="off"
                                    onChange={(e) => setCompanyName(e.target.value)}
                                />
                            </div>
                            <div className='col-md-6'>
                                <label htmlFor="name">Company Type</label>


                                <select
                                    id="companyType"
                                    name="companyType"
                                    className='iptag p-3'
                                    required
                                    autoComplete="off"
                                    value={companyType}
                                    onChange={(e) => SetCompanyType(e.target.value)}
                                >
                                    <option value=""disabled>Select Company Type</option>
                                    {categories.map((category) => (
                                        <option key={category.id} value={category.categoryType
                                        }>
                                            {category.categoryType
                                            }
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <label htmlFor="username">Company Logo</label>
                        <input
                            type="text"
                            placeholder="URL"
                            name="logo"
                            id="username"
                            required
                            autoComplete="off"
                            className='form-control iptag p-3'
                            value={logo}
                            onChange={(e) => SetLogo(e.target.value)}
                        />

                        <label htmlFor="address">Location</label>
                        <input
                            type="text"
                            placeholder="Location"
                            name="location"
                            className='form-control iptag p-3'
                            id="regzip"
                            required
                            autoComplete="off"
                            value={Location}
                            onChange={(e) => setLocation(e.target.value)}
                        />

                        <label>Company Description</label>
                        <textarea
                            type="text"
                            placeholder="Description"
                            rows={6}
                            className='form-control iptag'
                            name="companyDes"
                            value={companyDes}
                            onChange={(e) => SetCompanyDes(e.target.value)}
                        ></textarea>

                        <div className='text-end'>
                            <button type="submit" className='submitBtn' style={{ cursor: "pointer" }}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Adminfooter />
            <ToastContainer />
        </div>
    );
}

export default Addingcompany;
