import React, { useState, useEffect } from "react";
import Footer from "../components/footer";
import { useNavigate, useLocation } from "react-router-dom";
import apiList from "../lib/apilist";
import axios from "axios";
import Guestnavbar from "./guestnavbar";
import Cookies from "js-cookie";
import homeImg from "../images/creative-inspirational-resource_23-2149144209.jpg"

const PAGE_SIZE = 8;

function Guesthome() {
    const [categories, setCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const { state } = useLocation();

    const token = Cookies.get("token");

    const fetchData = async () => {
        try {
            const response = await axios.get(apiList.allcategories, {
                headers: {
                    token: token
                }
            });
            console.log(response.data);
            if (response.status === 200) {
                setCategories(response.data.map((category) => category.categoryType));
            } else {
                console.error("Failed to fetch category names");
            }
        } catch (error) {
            console.error("Error fetching category names:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const gotoIndividual = (companyName) => {
        navigate('/login', { state: { companyName } });
    };

    const [searchTerm, setSearchTerm] = useState(state?.searchTerm || '');
    const [dynamicCompanies, setDynamicCompanies] = useState([]);

    const filteredCompanies = dynamicCompanies.filter((company) =>
        company.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const totalPages = Math.ceil(filteredCompanies.length / PAGE_SIZE);
    const visiblePages = [1, 2, 3, 4];

    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;

    const visibleCompanies = filteredCompanies.slice(startIndex, endIndex);

    const fetchAllCompanies = async () => {
        try {
            const response = await axios.get(apiList.allcompanies, {
                headers: {
                    token: token
                }
            });
            setDynamicCompanies(response.data);
        } catch (error) {
            console.error("Error fetching companies", error);
        }
    };

    useEffect(() => {
        fetchAllCompanies();
    }, []);

    const getRatingBackgroundColor = (averageRating) => {
        if (averageRating <= 2) {
            return 'red';
        } else if (averageRating <= 4) {
            return '#9acd32';
        } else {
            return '#5ba829';
        }
    };

    const Jwttoken = Cookies.get("token");

    useEffect(() => {
        if (Jwttoken) {
            navigate("/userhome");
        }
    }, []);

    return (
        <div style={{ backgroundColor: "#f5f5f5" }}>
            <Guestnavbar setSearchTerm={setSearchTerm} />


            <section class="choose-us" style={{paddingTop:"150px"}}>
                <div class="container  p-2">
                    <div class="row mx-0">
                        <div class="col-12 col-sm-12 col-md-6  mb-lg-0">
                            <div class="">
                                <div class="pl-60 d-block w-100">
                                    {/* <div class="new-headding position-relative zilla_slab_regular font-size-36 line-height-1 wow fadeInDown font-weight-normal text-capitalize mt-0">
                                            <h4 className="aboutheading"> Choose US</h4>
                                        </div> */}
                                    <div class="my-3 wow fadeInUp">
                                        <div class="mb-3 zilla_slab_regular">
                                            <b>Confidentiality Assurance:</b><p className="aboutPara"> Our review system prioritizes the utmost security, ensuring that all feedback provided by employees is perfectly safeguarded. Employee details are meticulously concealed to guarantee privacy and protection.</p>
                                        </div>
                                        <div class="mb-3">
                                            <b>Stringent Privacy Measures: </b> <p className="aboutPara">We have implemented robust privacy protocols to assure employees that their identities remain confidential when sharing reviews. Our commitment to confidentiality extends to every facet of the review process.</p>
                                        </div>
                                        <div class="mb-3">
                                            <b>Anonymous Contributions: </b> <p className="aboutPara"> Employees can freely express their thoughts and insights knowing that their details are hidden. Our platform enables anonymous contributions, fostering an environment where genuine feedback can be shared without any concerns about privacy.</p>
                                        </div>
                                        <div class="mb-3">
                                            <b>Encrypted Platforms: :</b> <p className="aboutPara">Leveraging state-of-the-art encryption technologies, our review platform safeguards both the content and the identity of contributors. This ensures that only authorized individuals have access to the information, maintaining the integrity and security of the feedback.</p>
                                        </div>
                                        <div class="mb-3">
                                            <b>Transparent Security Practices: </b> <p className="aboutPara"> We believe in transparency regarding our security measures. Employees can trust that their reviews are treated with the highest level of confidentiality, and our practices align with industry standards to uphold the trust and confidence of our team.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                            <div className="d-flex align-items-center text-center justify-content-center">
                                <img src={homeImg} className="img-fluid"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container" style={{  backgroundColor: "#f5f5f5" }}>
                <div className="gridcontainer mb-5 mt-1" style={{ backgroundColor: "#f5f5f5" }}>
                    {visibleCompanies.map(company => (
                        <div
                            key={company.id}
                            className="d-md-flex justify-content-around mainCont p-1"
                            onClick={() => gotoIndividual(company.companyName)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div
                                className="mt-1 my-md-0 d-flex flex-column align-items-center justify-content-center"
                            >
                                <img
                                    src={company.logo}
                                    alt={company.companyName}
                                    className="img-fluid mb-1 mt-1"
                                    style={{ width: "45px", height: "45px", boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: '10px' }}
                                />
                                <span style={{ fontSize: "13px", fontWeight: "600", fontFamily: "sans-serif" }}>{company.companyName}</span>
                                <span style={{ fontSize: "13px" }}>{company.Location}</span>
                                <span style={{ fontSize: "13px" }}>{company.companyType}</span>

                                <div className="p-2">
                                    <h3 className="cardheading">About Company</h3>
                                    <div style={{ height: "100px", overflowY: "scroll" }}>
                                        <span style={{ fontSize: "13px", color: "#716e6e" }}>{company.companyDes}</span>
                                    </div>
                                    <div className="mt-2 d-flex justify-content-between">
                                        <span className="rating-value averageContainer" style={{ backgroundColor: getRatingBackgroundColor(company.averageRating) }}>
                                            ★ {company.averageRating}
                                        </span>
                                        <button className="BtnRvw" onClick={() => gotoIndividual(company.companyName)} style={{ cursor: "pointer", fontSize: "10px", padding: "8px 12px" }}>Write Your Review</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {filteredCompanies.length >= PAGE_SIZE && (
                    <div className="pagination-container text-center my-4">
                        <button
                            className="pagination-btn"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            &lt;
                        </button>
                        {visiblePages.map(page => (
                            <button
                                key={page}
                                className={`pagination-btn ${currentPage === page ? 'active' : ''}`}
                                onClick={() => handlePageChange(page)}
                            >
                                {page}
                            </button>
                        ))}
                        <button
                            className="pagination-btn"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            &gt;
                        </button>
                    </div>
                )}
            </div>

            <Footer />
        </div>
    );
}

export default Guesthome;
