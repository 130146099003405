import React from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';




function Footer() {
    return (
       
        <footer>
            <div class="top_header">
                <section>
                    <span><i class="fa fa-envelope"></i></span>
                    <span>info@reviewguru.com</span>
                </section>
            </div>
            <span class="border-shape"></span>
            <div class="bottom_content">
                <section>
                    <MuiLink to="#"  component={RouterLink} variant="body2" style={{ color: "white", textDecoration: "none" }}><i class="fa fa-facebook"></i></MuiLink>
                    <MuiLink to="#"  component={RouterLink} variant="body2" style={{ color: "white", textDecoration: "none" }}><i class="fa fa-instagram"></i></MuiLink>
                    <MuiLink to="#"  component={RouterLink} variant="body2" style={{ color: "white", textDecoration: "none" }}><i class="fa fa-twitter"></i></MuiLink>
                    <MuiLink to="#"  component={RouterLink} variant="body2" style={{ color: "white", textDecoration: "none" }}><i class="fa fa-telegram"></i></MuiLink>
                </section>
                <section>
                    <MuiLink to="/userhome"  component={RouterLink} variant="body2" style={{ color: "white", textDecoration: "none" }}>Home</MuiLink>
                    <MuiLink to="/about"  component={RouterLink} variant="body2" style={{ color: "white", textDecoration: "none" }}>About</MuiLink>
                    <MuiLink to="/contactus"  component={RouterLink} variant="body2" style={{ color: "white", textDecoration: "none" }}>Contact</MuiLink>
                </section>
            </div>
            <div class="copyright">
                Copyright © 2024 review guru - All rights reserved
            </div>
        </footer>
    )
}

export default Footer
