import React, { useState, useEffect } from "react";
import Footer from "../components/footer";
import apiList from "../lib/apilist";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import Usernavbar from "../components/userNavbar";
import Cookies from "js-cookie";
import Adminfooter from "./adminfooter";

function Category() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [dynamicCompanies, setDynamicCompanies] = useState([]);
  
  const token = Cookies.get("token");

  const gotoIndividual = (CName) => {
    navigate('/givereview', { state: { companyName: CName } });
  }

  const fetchAllCompanies = async () => {
    try {
      const response = await axios.get(`${apiList.companyType}/${state}`, {
        headers: {
          token: token
        }
      });
      setDynamicCompanies(response.data);
    } catch (error) {
      console.error("Error fetching companies", error);
    }
  };

  useEffect(() => {
    fetchAllCompanies();
  }, [state]);

  const filteredCompanies = dynamicCompanies.filter(company =>
    company.companyName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Usernavbar />

      <div className="text-center align-items-center justify-content-center secondContainer my-5 d-flex">
        <div className="d-none d-md-block col-md-1"></div>
        <div className="col-md-10">
          <h1 className="indus mt-5">Best in {state}</h1>
          <span className="sText">
            Compare the best companies in this category
          </span>
          <div className=" text-center">
            <input
              type="text"
              placeholder="search for a company or category"
              className=" p-2 searchBox  w-50 my-3"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button className=" registerButton">Search</button>
        </div>
        <div className="d-none d-md-block col-md-1"></div>
      </div>

      <div className="container">
        <div className="gridcontainer mb-5" style={{ backgroundColor: "white" }}>
          {filteredCompanies.map(company => (
            <div
              key={company.id}
              className="d-md-flex justify-content-around mainCont p-1"
            >
              <div
                className="mt-1 my-md-0 d-flex flex-column align-items-center justify-content-center"
              >
                <img
                  src={company.logo}
                  alt={company.companyName}
                  className="img-fluid mb-1 mt-1"
                  style={{ width: "45px", height: "45px", boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: '10px' }}
                />
                <span style={{ fontSize: "13px" }}>{company.Location}</span>
                <span style={{ fontSize: "13px" }}>{company.companyType}</span>

                <div className="p-2">
                  <h3 className="cardheading">About Company</h3>
                  <div style={{ height: "100px", overflowY: "scroll" }}>
                    <span style={{ fontSize: "13px", color: "#716e6e" }}>{company.companyDes}</span>
                  </div>
                  <div className="mt-2 d-flex justify-content-end">
                    <button className="BtnRvw" onClick={() => gotoIndividual(company.companyName)} style={{ cursor: "pointer", fontSize: "10px", padding: "8px 12px" }}>Write Your Review</button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Adminfooter />
    </div>
  );
}

export default Category;
