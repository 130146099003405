import React from "react";
import { useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import Cookies from "js-cookie"; // Import js-cookie library
import logo1 from "../images/Asset 2.png";
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import apiList from '../lib/apilist';
import MuiLink from '@mui/material/Link';
import { Link } from "react-router-dom";

function Guestnavbar(props) {
    let navigate = useNavigate();
    const gotoLogin = () => {
        navigate('/login');
    }

    const [searchInput, setSearchInput] = useState('');

    const handleSearchInputChange = (event) => {
        const searchTerm = event.target.value;
        setSearchInput(searchTerm);
        props.setSearchTerm(searchTerm); // Pass the search term to the parent component
    };


    return (
        <div style={{backgroundColor:"#f5f5f5"}}>
            <div className="navigation-wrap bg-light start-header start-style">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="navbar navbar-expand-md navbar-light">
                                <a className="navbar-brand" href="" target="_blank">
                                    <img src={logo1} alt="" />
                                </a>
                                <input type="text" placeholder="search companies,brands,etc..." className="p-2 searchBox " value={searchInput}
                                    onChange={handleSearchInputChange}
                                />
                                <MuiLink to="/guestcontact" component={RouterLink} variant="body2" style={{ color: "black", textDecoration: "none" , marginLeft:"10px", marginTop:"13px" }}>
                                   Contact
                                </MuiLink>


                                <div className="d-none d-lg-block">
                                    <div className="" id="">

                                        <div className="row">
                                            <div className="col-md-6">
                                                <button class="login100-form-btn ml-4" style={{ fontSize: "16px", cursor: "pointer", fontFamily: "sans-serif", minWidth: "100px", height: "40px", border: "silver 1px solid" }} onClick={gotoLogin} >
                                                    Login
                                                </button>
                                            </div>
                                            <div className="col-md-6">
                                                <div class="contact100-form-checkbox">
                                                    <MuiLink to="/signup" component={RouterLink} variant="body2" style={{ color: "black", textDecoration: "none" }}>
                                                        {"Don't have an account?"}<span style={{ color: "#57b846" }}>signup</span>
                                                    </MuiLink>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </nav>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );
}

export default Guestnavbar;
