
import React from "react";
import Footer from "../components/footer";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import apiList from "../lib/apilist";
import axios from "axios";
import Navbar from '../Admin/navbar';
import Cookies from "js-cookie";
import Adminfooter from "./adminfooter";

const PAGE_SIZE = 8;

function Adminhome() {
    const [categories, setCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const { state } = useLocation();

    const token = Cookies.get("admintoken");

    const fetchData = async () => {
        try {
            const response = await axios.get(apiList.allcategories, {
                headers: {
                    token: token
                }
            });
            console.log(response.data);
            if (response.status === 200) {
                setCategories(response.data.map((category) => category.categoryType));
            } else {
                console.error("Failed to fetch category names");
            }
        } catch (error) {
            console.error("Error fetching category names:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const gotoIndividual = (CName) => {
        navigate('/seereviews', { state: { companyName: CName } });
    }


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    const [dynamicCompanies, setDynamicCompanies] = useState([]);




    const fetchAllCompanies = async () => {
        try {
            const response = await axios.get(apiList.allcompanies);
            setDynamicCompanies(response.data);
        } catch (error) {
            console.error("Error fetching companies", error);
        }
    };


    useEffect(() => {
        fetchAllCompanies();
    });


    const totalPages = Math.ceil(dynamicCompanies.length / PAGE_SIZE);
    const visiblePages = [1, 2, 3, 4];

    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;

    const visibleCompanies = dynamicCompanies.slice(startIndex, endIndex);


    const getRatingBackgroundColor = (averageRating) => {
        if (averageRating <= 2) {
            return 'red';
        } else if (averageRating <= 4) {
            return '#9acd32';
        } else {
            return '#5ba829';
        }
    };
   





    return (
        <div style={{ backgroundColor: "#f5f5f5" }}>
            <Navbar />


            <div className="container" style={{ paddingTop: "80px", backgroundColor: "#f5f5f5" }}>
                <div className="gridcontainer mb-5 mt-3" style={{ backgroundColor: "#f5f5f5" }}>
                    {visibleCompanies.map(company => (
                        <div
                            key={company.id}
                            className="d-md-flex justify-content-around mainCont p-1"
                            onClick={() => gotoIndividual(company.companyName)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div
                                className="mt-1 my-md-0 d-flex flex-column align-items-center justify-content-center"
                            >
                                <img
                                    src={company.logo}
                                    alt={company.companyName}
                                    className="img-fluid mb-1 mt-1"
                                    style={{ width: "45px", height: "45px", boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: '10px' }}
                                />
                                <span style={{fontSize:"13px", fontWeight:"600", fontFamily:"sans-serif"}}>{company.companyName}</span>
                                <span style={{ fontSize: "13px" }}>{company.Location}</span>
                                <span style={{ fontSize: "13px" }}>{company.companyType}</span>

                                <div className="p-2">
                                    <h3 className="cardheading">About Company</h3>
                                    <div style={{ height: "100px", overflowY: "scroll" }}>
                                        <span style={{ fontSize: "13px", color: "#716e6e" }}>{company.companyDes}</span>
                                    </div>
                                    <div className="mt-2 d-flex justify-content-between">
                                        <span className="rating-value averageContainer" style={{ backgroundColor: getRatingBackgroundColor(company.averageRating) }}>
                                            ★ {company.averageRating}
                                        </span>
                                        <button className="BtnRvw" onClick={() => gotoIndividual(company.companyName)} style={{ cursor: "pointer", fontSize: "10px", padding: "8px 12px" }}>See Review</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="pagination-container text-center my-4">
                    <button
                        className="pagination-btn"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        &lt;
                    </button>
                    {visiblePages.map(page => (
                        <button
                            key={page}
                            className={`pagination-btn ${currentPage === page ? 'active' : ''}`}
                            onClick={() => handlePageChange(page)}
                        >
                            {page}
                        </button>
                    ))}
                    <button
                        className="pagination-btn"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        &gt;
                    </button>
                </div>
            </div>
            <Adminfooter />
        </div >
    )
}

export default Adminhome
