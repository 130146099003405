import React, { useEffect } from 'react'
import Usernavbar from './userNavbar'
import Footer from "../components/footer";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
function About() {

    const navigate = useNavigate();
    const gotoLogin = () => {
        navigate("/login")
    }
    const gotoSignUp = () => {
        navigate("/signup")
    }

    const token = Cookies.get("token")


    const isTokenValid = () => {
        const jwtToken = Cookies.get('token');

        // Check if the token exists and is not expired
        return jwtToken ? true : false;
    };


    useEffect(() => {
        // Check if the token is not valid, then redirect to the login page
        if (!isTokenValid()) {
            navigate('/login');
        }
    }, []);


    return (
        <div className='about-us-page1'>
            <Usernavbar />

            <div className="about-us-page container mt-3">
                <h1 className="text-center mb-4">About us</h1>

                <div className="row">
                    <div className="col-lg-6">
                        <h2 className="mb-4 aboutheading">About REVIEW GURU</h2>
                        <p className='aboutPara'>
                            Review Guru envisions the creation of an independent currency of trust. As a digital platform, we facilitate the convergence of businesses and consumers, fostering trust and inspiring collaboration. Review Guru operates on principles of transparency, is open to all, and offers free accessibility, aiming to revolutionize the dynamics between businesses and consumers.
                        </p>
                        <p className='aboutPara'>
                            Review Guru serves as a platform for consumers to shop with confidence by hosting reviews. Additionally, we provide valuable insights to assist businesses in enhancing their customer experiences. The increased engagement of consumers on our platform, sharing their opinions, contributes to a wealth of insights available to businesses. This, in turn, creates more opportunities for businesses to cultivate trust with consumers globally through Review Guru.
                        </p>

                    </div>
                    <div className="col-lg-6 align-items-center d-flex">
                        <img
                            src="https://img.freepik.com/free-photo/student-boy-using-laptop-learning-online-education_1150-16905.jpg?w=996&t=st=1703835485~exp=1703836085~hmac=75c3ba5023bdb3769d76c58f7f07e25befd258e27c81e41f19eb54162531b07c"
                            alt="About NOWNESS image"
                            className="img-fluid"
                            style={{ borderRadius: "15px", height: "300px" }}
                        />
                    </div>
                </div>

                {/* Additional content from the image */}
                <div className="row mt-2">
                    <div className="col-md-12">
                        <p className='aboutPara'>
                            In this era of heightened consumer consciousness, it is imperative for corporate leaders and company managers not to overlook the sentiments expressed by consumers about their products. Executives have turned to Review Guru to gain a better understanding of their customers. Thanks to Review Guru, a rare scenario is emerging where companies from different ends of the market spectrum are working together to achieve commercial excellence.


                        </p>


                    </div>
                </div>


            </div>
            <div className='about-us-page container mt-4'>
                <div>
                    <h2 className='aboutheading mb-4'>Why US ?</h2>
                    <p className='aboutPara'> Illuminating Excellence in Employee Feedback</p>
                    <p className='aboutPara'>

                        At Review Guru, our mission is to shed light on companies committed to delivering innovative and effective lighting solutions. We serve as a platform for employees to provide insightful reviews on their workplace experiences. We take pride in creating an environment where users can share feedback on companies that prioritize not only world-class products and services but also employee satisfaction.

                        We encourage reviews that highlight a company's commitment to creating environmentally friendly products, ensuring optimum lighting results with minimal energy consumption. Companies that consistently meet or exceed customer requirements and expectations, delivering on their promises with guaranteed product quality, are the focus of Review Guru.

                        Our platform is designed to empower employees to share their thoughts on a company's customer service, sales force expertise, and overall commitment to excellence in the lighting industry. With flexibility at the core, we adapt to the diverse needs of our users, enabling tailored reviews that reflect the specific experiences of each employee.

                    </p>
                </div>

                <div>
                    <h2 className='aboutheading mb-4'>Vision & Mission</h2>
                    <p className='aboutPara'>At Review Guru, we provide a dedicated platform for employees to share their valuable insights and reviews about companies committed to delivering cutting-edge lighting solutions. Our focus is on facilitating a transparent and informative space where employees can highlight their experiences with companies that excel in the industry.</p>
                    <p className='aboutPara'>When it comes to keeping promises and delivering guaranteed quality, Review Guru recognizes and celebrates companies that stand by their commitments. We emphasize the importance of excellent customer service and a knowledgeable sales force, acknowledging organizations that prioritize a high level of expertise in their Fields.</p>
                    <p className='aboutPara'>Review Guru is committed to flexibility, enabling employees to share tailored reviews that accurately reflect their unique perspectives on company practices. Join us in empowering employees to contribute valuable feedback and shed light on companies that shine in providing exceptional solutions and fostering positive workplace experiences.</p>
                </div>

            </div>
            <div className="about-us-page container mt-5 mb-5">
                <header className="text-start">
                    <h2 className='aboutheading mb-3'>How do reviews get on Review Guru?</h2>
                    <p className="aboutPara">
                        We’re open to all. Reviews are published without moderation.
                    </p>
                </header>
                <div className='container'>
                    <div className="row">
                        
                            <div className="col-md-12 col-lg-6 col-12 service-item">
                                <div className='inside_div card'>
                                    <h2 className='aboutheading1 text-center'>Being open is in our DNA</h2>
                                    <p className='aboutPara'>At Review Guru, we uphold a unique approach that sets us apart from 'closed' or pay-to-access platforms. Unlike platforms where businesses can selectively showcase reviews for marketing purposes, Review Guru ensures transparency and authenticity by allowing anyone to post a review.</p>
                                    <p className='aboutPara'>With Review Guru, the power lies in the hands of those who share their experiences.
                                        <br></br>

                                        Where Every Voice Matters, and Every Review is Published Without Moderation.</p>
                                </div>

                            </div>
                            <div className="col-md-12 col-lg-6 col-12 service-item">
                                <div className='inside_div card'>
                                    <h2 className='aboutheading1 text-center'>Empowering Review Ownership and Control</h2>
                                    <p className='aboutPara'> </p>
                                    <p className='aboutPara'>At Review Guru, we hold a firm belief that reviews are the valuable user-generated content owned by their authors. To ensure transparency and user autonomy, we've established a system where only the original authors have the authority to edit or permanently delete their reviews. This control is conveniently facilitated through their user accounts.</p>
                                </div>

                            </div>
                        


                    </div>
                </div>
                <div className='container'>
                  
                        <div className='row'>
                            <div className="col-md-12 col-lg-6 col-12 service-item">
                                <div className='inside_div card'>
                                <h2 className='aboutheading1 text-center'>Rigorous Fraud Detection </h2>
                                <p className='aboutPara'>At Review Guru, each review undergoes meticulous screening through our proprietary automated fraud detection software. This specialized system is designed to analyze various data points, including IP addresses, user identifiers, device characteristics, location data, and timestamps. Its purpose is to identify patterns indicative of suspicious behavior and promptly remove any potentially fake reviews.</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 col-12 service-item">
                                <div className='inside_div card'>
                                <h4 className='aboutheading1 text-center'>Ensuring Integrity Through Flagging Mechanisms</h4>
                                <p className='aboutPara'>At Review Guru, our commitment to openness extends to providing both businesses and consumers with the ability to flag a review if it violates our guidelines. We believe that maintaining a platform where users can report potential guideline violations is crucial for upholding the quality and authenticity of the reviews on our platform.</p>
                                </div>
                            </div>
                        </div>

                </div>



                {/* <div className='row mb-5'>

                    <div className="col-6">
                        <p className='mt-4 aboutLast' onClick={gotoLogin} style={{ cursor: "pointer" }}>ALREADY A MEMBER? SIGN IN</p>
                    </div>
                    <div className='col-6'>
                        <button onClick={gotoSignUp} className="aboutBtn mt-4">BECOME A USER</button>
                    </div>
                </div> */}
            </div>

            <Footer />
        </div>
    )
}

export default About
