import React, { useState } from 'react';
import axios from 'axios';
import apiList from '../lib/apilist';

function AddCategoryTypeForm() {
    const [categoryType, setCategoryType] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(apiList.addcategory, {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ categoryType })
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(errorMessage);
            }

            const newCategoryType = await response.json();
            console.log('New category type:', newCategoryType);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <label htmlFor="categoryType">Category Type:</label>
            <input type="text" id="categoryType" value={categoryType} onChange={(event) => setCategoryType(event.target.value)} />
            <button type="submit">Add Category Type</button>
        </form>
    );
}

export default AddCategoryTypeForm;