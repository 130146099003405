import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie"; // Import js-cookie library
import logo1 from "../images/Asset 2.png";
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import apiList from '../lib/apilist';
import MuiLink from '@mui/material/Link';
import { Link as RouterLink } from "react-router-dom";

function Navbar() {
    let navigate = useNavigate();
    const token = Cookies.get("admintoken")

    const gotoAddCompany = () => {
        navigate('/addcompany')
    }


    const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
    const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);

    const handleDropdownToggle = () => {
        setIsDropdownOpen1(!isDropdownOpen1);
    };

    const handleLogout = () => {
        // Remove token and userName cookies
        Cookies.remove("admintoken");
        Cookies.remove("userName");

        // Redirect to the login page or any other page
        navigate("/login");
    };

    const [categoryType, setCategoryType] = useState('');

    const handleShowAddCategoryModal = () => {
        setShowAddCategoryModal(true);
    };

    const handleCloseAddCategoryModal = () => {
        setShowAddCategoryModal(false);
    };

    const handleCategoryChange = (event) => {
        setCategoryType(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(apiList.addcategory, { categoryType }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 201) {
                const newCategoryType = response.data;
                console.log('New category type:', newCategoryType);
                handleCloseAddCategoryModal(); // Close the modal after successful submission
            } else {
                throw new Error(`Request failed with status ${response.status}`);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    useEffect(()=>{
        if(token===null||token===undefined)
        {
            navigate("/login")
        }
    },[])



    return (
        <div>
            <div className="navigation-wrap bg-light start-header start-style">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="navbar navbar-expand-md navbar-light">
                                <a className="navbar-brand" href="#" target="_blank">
                                    <img src={logo1} alt="" />
                                </a>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav ml-auto py-4 py-md-0">
                                        <li className=" pl-4 pl-md-0 ml-0 ml-md-4">
                                            <MuiLink className="nav-link" to="/adminhome" component={RouterLink} variant="body2" style={{ color: "black", textDecoration: "none" }}>
                                                Home
                                            </MuiLink>
                                        </li>
                                        <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                                            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Add</a>
                                            <div class="dropdown-menu">
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" onClick={gotoAddCompany}>Add Company</a>
                                                    <a className="dropdown-item" onClick={handleShowAddCategoryModal}>
                                                        Add Category
                                                    </a>
                                                </div>

                                            </div>
                                        </li>

                                        <li className=" pl-4 pl-md-0 ml-0 ml-md-4">
                                            <MuiLink className="nav-link" to="/allusers" component={RouterLink} variant="body2" style={{ color: "black", textDecoration: "none" }}>
                                                All Users
                                            </MuiLink>
                                        </li>


                                        <li className=" pl-4 pl-md-0 ml-0 ml-md-4">
                                            <MuiLink className="nav-link" to="/adminabout" component={RouterLink} variant="body2" style={{ color: "black", textDecoration: "none" }}>
                                                About
                                            </MuiLink>
                                        </li>
                                        <li className=" pl-4 pl-md-0 ml-0 ml-md-4">
                                            <span className="nav-link" onClick={handleLogout} href="#" style={{cursor:"pointer"}}>
                                                Logout
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showAddCategoryModal} onHide={handleCloseAddCategoryModal} centered>
                {/* <Modal.Header>
                    <Modal.Title>Add Category</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="categoryType">
                            <div className="text-center">
                                <Form.Label className="modalLabel">Category Type</Form.Label>
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Enter category type"
                                value={categoryType}
                                onChange={handleCategoryChange}
                                className="modalIp"
                            />
                        </Form.Group>
                        <div className="text-center">
                            <button type="submit" className="mt-2 modalBtn">
                                Add Category
                            </button>
                        </div>

                    </Form>
                </Modal.Body>
            </Modal>

        </div>

    );
}

export default Navbar;
