import {Routes, Route} from 'react-router-dom';
import Login from './authentication/login';
import Auth from './authentication/signup';

import Category from './Admin/category';
import GiveReview from './user/giveReview';
import Addingcompany from './Admin/addingcompany';
import AddCategoryTypeForm from './Admin/addingCategory';
import About from './components/about';
import Guesthome from './guestUser.js/home';
import Userhome from './user/home';
import Adminhome from './Admin/adminhome';
import Adminabout from './Admin/adminabout';
import Givenreview from './Admin/givenReviews';
import Allusers from './Admin/allusers';
import Contactus from './components/contactus';
import Guestontactus from './guestUser.js/guestContact';
import Adminfooter from './Admin/adminfooter';
import AdminContact from './Admin/admincontact';
import Otpverification from './authentication/otpverification';
import Changepassword from './authentication/changePassword';

function App() {
  return (
    <div>
      <Routes>
        <Route path='/login' element={<Login/>}/>
        <Route path='/signup' element={<Auth/>}/>
        <Route path='/userhome' element={<Userhome/>}/>
        <Route path='/category' element={<Category/>}/>
        <Route path='/givereview' element={<GiveReview/>}/>
        <Route path='/addcompany' element={<Addingcompany/>}/>
        <Route path='/addcategory' element={<AddCategoryTypeForm/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/' element={<Guesthome/>}/>
        <Route path='/adminhome' element={<Adminhome/>}/>
        <Route path='/adminabout' element={<Adminabout/>}/>
        <Route path='/seereviews' element={<Givenreview/>}/>
        <Route path='/allusers' element={<Allusers/>}/>
        <Route path='/contactus' element={<Contactus/>}/>
        <Route path='/guestcontact' element={<Guestontactus/>}/>
        <Route path='/adminfooter' element={<Adminfooter/>}/>
        <Route path='/admincontact' element={<AdminContact/>}/>
        <Route path='/otpverify' element={<Otpverification/>}/>
        <Route path='/changepassword' element={<Changepassword/>}/>
      </Routes>
    </div>
  );
}

export default App;
