import React, { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import loginlogo from "../images/reviewguulogoremovebg-preview.png";
import axios from "axios";
import apiList from "../lib/apilist";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";

import MuiLink from '@mui/material/Link';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';

function Changepassword() {
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const { state } = useLocation();
    console.log(state)

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if passwords match
        if (newPassword !== confirmNewPassword) {
            toast.error("New password and confirm password do not match");
            return;
        }

        try {
            // Make a PUT request to update the password on the server
            const response = await axios.post(`${apiList.changepassword}/${state}`, {
                newPassword,
            });

            if (response.status === 200) {
                // Successful response
                toast.success("Password updated successfully");
                setConfirmNewPassword("");
                setNewPassword("");
                setTimeout(() => {
                    navigate('/login')
                }, 2000);
            } else {
                // Error response
                toast.error("Failed to update password. Please try again.");
            }
        } catch (error) {
            // Handle network or other errors
            console.error("Error:", error);
            toast.error("Something went wrong. Please try again later.");
        }
    };

    


    return (

        <div className="">
            <ToastContainer />
            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100">
                        <div className="login100-form-title" style={{ backgroundImage: "url()" }}>
                            <span className="login100-form-title-1">
                                Change Password
                            </span>
                        </div>

                        <form className="login100-form validate-form" onSubmit={handleSubmit}>

                            <div className="wrap-input100 validate-input m-b-18 mt-3" data-validate="Password is required">
                                <span className="label-input100"> New Password</span>
                                <div className="password-input-container d-flex">
                                    <input
                                        className="input100"
                                        type={showPassword ? "text" : "password"}
                                        name="pass"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        placeholder="Enter password"
                                    />
                                    <span
                                        className="toggle-password-icon"
                                        style={{ marginTop: "15px" }}
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                                    </span>
                                </div>
                                <span className="focus-input100"></span>
                            </div>

                            <div className="wrap-input100 validate-input m-b-18 mt-3" data-validate="Password is required">
                                <span className="label-input100">Confirm New Password</span>
                                <div className="password-input-container d-flex">
                                    <input
                                        className="input100"
                                        type={showPassword ? "text" : "password"}
                                        name="pass"
                                        value={confirmNewPassword}
                                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                                        placeholder="Enter password"
                                    />
                                    <span
                                        className="toggle-password-icon"
                                        style={{ marginTop: "15px" }}
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                                    </span>
                                </div>
                                <span className="focus-input100"></span>
                            </div>

                            <div className="container-login100-form-btn">
                                <button className="login100-form-btn mt-3">
                                    Submit
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Changepassword;
