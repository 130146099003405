import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './navbar';
import apiList from '../lib/apilist';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Adminfooter from './adminfooter';

function AllUsers() {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const token = Cookies.get("admintoken");
  const navigate = useNavigate();

  const fetchAllUsers = async () => {
    try {
      const response = await axios.get(apiList.allusers, {
        headers: {
          token: token
        }
      });

      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const isTokenValid = () => {
    const jwtToken = Cookies.get('admintoken');

    // Check if the token exists and is not expired
    return jwtToken ? true : false;
  };

  useEffect(() => {
    // Check if the token is not valid, then redirect to the login page
    if (!isTokenValid()) {
      navigate('/login');
    }
  }, []);

  return (
    <div>
      <Navbar />
      <div className="" style={{ marginTop: "100px" }}>
        <table class="table table-hover">
          <thead>
            <tr className='text-center'>
              <th>Name</th>
              <th>Designation</th>
              <th>Company Name</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map(user => (
              <tr key={user.userName} className='text-center'>
                <td>{user.userName}</td>
                <td>{user.designation}</td>
                <td>{user.company}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination */}
        <ul className="pagination justify-content-center mr-5">
          {Array.from({ length: Math.ceil(users.length / itemsPerPage) }, (_, i) => (
            <li
              key={i}
              className={`page-item ${i + 1 === currentPage ? 'active' : ''}`}
              style={{ backgroundColor: 'lightgray' }}
            >
              <button
                onClick={() => paginate(i + 1)}
                className="page-link"
                style={{
                  color: i + 1 === currentPage ? 'white' : 'black',
                  backgroundColor: i + 1 === currentPage ? '#57b846' : 'inherit',
                  border: 'none',
                }}
              >
                {i + 1}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <Adminfooter />
    </div>
  );
}

export default AllUsers;
