import React, { useState, useEffect } from "react";
import Footer from '../components/footer';
import apiList from "../lib/apilist";
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import Usernavbar from "../components/userNavbar";

function GiveReview() {
    const { state } = useLocation();
    const CompanyName = state.companyName;
    const [rating, setRating] = useState(0);
    const [reviewText, setReviewText] = useState("");
    const userName = Cookies.get('userName');
    const [dynamicCompanies, setDynamicCompanies] = useState([]);
    const [likes, setLikes] = useState([]);
    const [dislikes, setDislikes] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [internalArray, setInternal] = useState([]);
    const [recommendation, setRecommendation] = useState("");
    const [designation, setDesignation] = useState("")
    const [allUsers, setAllUsers] = useState([]);

    const [like, setLike] = useState([]);
    const token = Cookies.get("token")

    const fetchAllCompanies = async () => {
        try {
            const response = await axios.get(`${apiList.individualCompany}/${CompanyName}`, {
                headers: {
                    token: token
                }
            });
            setDynamicCompanies(response.data);
            setInternal(CateogryFields)


        } catch (error) {
            console.error("Error fetching companies", error);
        }
    };

    const desgn = Cookies.get("designation")



    const fetchCompanyTypes = async () => {
        try {
            const response = await axios.get(apiList.allcategories, {
                headers: {
                    token: token
                }
            });
            setCompanyTypes(response.data);

        } catch (error) {
            console.error("Error fetching company types", error);
        }
    };
    useEffect(() => {
        fetchAllCompanies();
        fetchCompanyTypes();
    }, []);


    const renderReviewCategories = () => {
        return (
            <div className="review-categories-grid">
                {internalArray.map((category) => (
                    <div key={category} className="category-item">
                        <span style={{ minWidth: "100px" }}>{category} :</span>
                        <span className={`ratingStars-${category.toLowerCase()} ml-2`}>
                            <div className={`star-container ratingStars-${ratings[category] || 0}`}>
                                {[1, 2, 3, 4, 5].map((star) => (
                                    <i
                                        key={star}
                                        className={`ri-star-line star`}
                                        style={{
                                            color:
                                                (ratings[category] >= 1 && ratings[category] <= 2 && star <= ratings[category]) ? 'red' :
                                                    (ratings[category] === 5 && star <= ratings[category]) ? '#5ba829' :
                                                        (ratings[category] >= 3 && star <= ratings[category]) ? '#ff9a05' :
                                                            '',
                                        }}
                                        onClick={() => setRatingFunction(category, star)}
                                    ></i>
                                ))}
                            </div>

                        </span>
                    </div>
                ))}
            </div>
        );
    };




    const submitReview = async () => {
        if (!dynamicCompanies || !dynamicCompanies.reviews) {
            console.error("Reviews data is not available.");
            toast.error('Error submitting review. Please try again.');
            return;
        }

        if (rating === 0 || reviewText.trim() === '') {
            toast.warning('Please provide both rating and review before submitting.');
            return;
        }

        // Check if all category ratings are provided
        const missingCategories = internalArray.filter(category => !ratings[category]);

        if (missingCategories.length > 0) {
            toast.warning(`Please provide ratings for the following categories: ${missingCategories.join(', ')}`);
            return;
        }

        const userAlreadyReviewed = dynamicCompanies.reviews.some(review => review.name === userName);

        if (userAlreadyReviewed) {
            toast.error('You have already submitted a review for this company.');
            return;
        }

        try {
            const response = await axios.post(`${apiList.sendReview}/${CompanyName}`, {
                name: userName,
                designation: desgn,
                rating,
                review: reviewText,
                submittedDate: new Date().toISOString(),
                categoryFields: ratings,
                likes: likes,
                dislikes: dislikes,
                recommendation: recommendation
            });

            console.log(response.data);

            const updatedResponse = await axios.get(`${apiList.individualCompany}/${CompanyName}`, {
                headers: {
                    token: token
                }
            });
            setDynamicCompanies(updatedResponse.data);
            toast.success('Review submitted successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 3000);

            setRating(0);
            setReviewText('');
            setDislikes([]);
            setLikes([]);
        } catch (error) {
            console.error("Error submitting review", error);
            toast.error('Error submitting review. Please try again.');
        }
    };


    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear().toString();
        return `${day}-${month}-${year}`;
    };
    const CateogryFields = [
        "Work-Life Balance",
        "Company Culture",
        "Career Development",
        "Management and Leadership",
        "Compensation and Benefit",
        "Work Environment",
        "Job Satisfaction",
        "Communication",
        "Technology and Tools",
        "Recognition and Rewards"
    ]

    const [ratings, setRatings] = useState({});

    const setRatingFunction = (category, star) => {
        // Map star ratings to like/dislike categories
        const likeDislikeMapping = {
            5: 'excellent',
            4: 'good',
            3: 'good',
            2: 'bad',
            1: 'terrible',
        };

        // Determine whether the rating is for likes or dislikes
        const likeDislikeCategory = likeDislikeMapping[star];

        // Remove previous like/dislike for the same category
        setLikes((prevLikes) => prevLikes.filter((like) => !like.includes(category)));
        setDislikes((prevDislikes) => prevDislikes.filter((dislike) => !dislike.includes(category)));

        // Update likes/dislikes arrays based on the category
        if (likeDislikeCategory === 'excellent') {
            setLikes((prevLikes) => [...prevLikes, ` Excellent ${category}`]);
        } else if (likeDislikeCategory === 'good') {
            setLikes((prevLikes) => [...prevLikes, ` Good ${category}`]);
        } else if (likeDislikeCategory === 'bad') {
            setDislikes((prevDislikes) => [...prevDislikes, ` Bad ${category}`]);
        } else if (likeDislikeCategory === 'terrible') {
            setDislikes((prevDislikes) => [...prevDislikes, ` Terrible ${category}`]);
        }

        // Update the ratings state with the selected rating for the given category
        setRatings((prevRatings) => ({
            ...prevRatings,
            [category]: star,
        }));
    };


    const handleLike = async (reviewId, status) => {
        try {
            // Make the API call to like a review
            const response = await axios.post(
                `${apiList.likes}/${CompanyName}`,
                {
                    likes: status,
                    reviewId: reviewId,
                    name: userName,
                }
            );
            if (status === true) {

                fetchAllCompanies();
            }
            if (status === false) {

                fetchAllCompanies();
            }
            console.log(response.data); // Handle the response as needed
        } catch (error) {
            console.error("Error liking review:", error);
            // Handle error as needed
        }
    };

    const handleReport = async (reviewId, status) => {
        try {
            // Make the API call to like a review
            const response = await axios.post(
                `${apiList.report}/${CompanyName}`,
                {
                    reports: status,
                    reviewId: reviewId,
                    name: userName,
                }
            );
            if (status === true) {

                fetchAllCompanies();
            }
            if (status === false) {

                fetchAllCompanies();
            }
            console.log(response.data); // Handle the response as needed
        } catch (error) {
            console.error("Error liking review:", error);
        }
    };

    




    return (
        <div style={{backgroundColor:"#f5f5f5"}}>
            <ToastContainer position="top-center" autoClose={1000} />
            <Usernavbar />
            <div className="container" style={{ paddingTop:"130px", backgroundColor:"#f5f5f5"}}>
                <div className="" style={{ backgroundColor: "#f5f5f5" }}>
                    <div className="" style={{backgroundColor:"#f5f5f5"}}>
                        <div className="row" style={{ margin: "auto", display: "flex", justifyContent: "space-evenly", backgroundColor:"#f5f5f5" }}>
                            <div className="mainCont mb-5 col-md-4" >
                                <div className=" my-3 my-md-0 d-flex flex-column align-items-center justify-content-center">
                                    <img
                                        src={dynamicCompanies.logo}
                                        alt={dynamicCompanies.companyName}
                                        className="img-fluid  mt-2"
                                        style={{ width: "60px", boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: '10px' }}
                                    />
                                    <span className="mb-2">{dynamicCompanies.Location}</span>
                                </div>

                                <div className="">
                                    <h3 className="cardheading">Bring forth a review</h3>
                                    <div className={`star-container ratingStars-${rating} mb-2`}>
                                        {[1, 2, 3, 4, 5].map((star) => (
                                            <i
                                                key={star}
                                                className={`ri-star-fill star`}
                                                onClick={() => setRating(star)}
                                                style={{
                                                    color:
                                                        (rating >= 1 && rating <= 2 && star <= rating) ? 'red' :
                                                            (rating === 5) ? '#5ba829' :
                                                                (rating >= 3 && star <= rating) ? '#ff9a05' :
                                                                    '',
                                                }}
                                            ></i>
                                        ))}
                                    </div>
                                    <textarea
                                        rows={6}
                                        className="form-control"
                                        placeholder="Write your comments..."
                                        style={{ border: "none", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                                        value={reviewText}
                                        onChange={(e) => setReviewText(e.target.value)}
                                    ></textarea>
                                    <p className="mt-5">would you recommend {CompanyName} for Freshers ?</p>
                                    <div class="radiogroup">
                                        <div class="wrapper">
                                            <input class="state" type="radio" name="app" id="a" value="Yes" onChange={(e) => setRecommendation(e.target.value)} />
                                            <label class="label" for="a">
                                                <div class="indicator"></div>
                                                <span class="text"> Yes</span>
                                            </label>
                                        </div>
                                        <div class="wrapper">
                                            <input class="state" type="radio" name="app" id="b" value="No" onChange={(e) => setRecommendation(e.target.value)} />
                                            <label class="label" for="b">
                                                <div class="indicator"></div>
                                                <span class="text"> No</span>
                                            </label>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="col-md-7 mb-5 parentcont" >
                                <div className="">
                                    <div className="content">
                                        <div className="mouthshut-score">
                                            <span>Average Rating</span>
                                           
                                        </div>
                                        <div className="rating">
                                            <span>{dynamicCompanies.averageRating} / 5</span>
                                            
                                        </div>
                                        <div className="categories">
                                            {renderReviewCategories(dynamicCompanies.companyType)}
                                        </div>

                                        <div className="write-review">
                                            <button onClick={submitReview} className="rvwBtn" style={{ marginBottom: "5px" }}>Submit Your Review</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hcltech-reviews mb-5">
                    <div className="reviews">
                        <h2 style={{ margin: "20px" }}>{dynamicCompanies.companyName} Reviews</h2>
                        {dynamicCompanies.reviews && [...dynamicCompanies.reviews].reverse().map((review, index) => (
                            <div className="review-card" key={index}>
                                <div className="review-header">
                                    <h3>{review.designation}</h3>
                                </div>
                                <div className="review-body">
                                    <p style={{ color: "#6b6868", fontSize: "13px" }}>{review.review}</p>
                                    <div className="likes-dislikes">
                                        <div className="likes">
                                            <span>Likes:</span>
                                            <ul>
                                                {review.likes.map((like, index) => (
                                                    <li style={{ color: "#6b6868" }} key={index}>{like}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="dislikes">
                                            <span>Dislikes:</span>
                                            <ul>
                                                {review.dislikes.map((dislike, index) => (
                                                    <li style={{ color: "#6b6868", }} key={index}>{dislike}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="review-footer">
                                        <span>Anonymous</span>
                                        <span>Recommendation : {review.recommendation}</span>
                                        <span>Submitted : {formatDate(new Date(review.submittedDate))}</span>

                                    </div>

                                    <div className="mt-2 userreview-usefulcontainer">
                                        <div className="d-flex justify-content-between">
                                            <div
                                                className=""
                                                style={{
                                                    // border: "1px solid #ccc",
                                                    // borderRadius: "5px",
                                                    // backgroundColor: "#d8d6d6",
                                                }}
                                            >
                                                {review.liked.some(
                                                    (like) =>
                                                        like.name === userName &&
                                                        like.likes === true
                                                ) ? (
                                                    <span className="px-1">
                                                        <i
                                                            class="fa-solid fa-thumbs-up px-1"
                                                            style={{
                                                                fontSize: "24px",
                                                                color: "#57b846",
                                                                cursor:"pointer"
                                                            }}
                                                        ></i>{" "}
                                                        {
                                                            review.liked.filter(
                                                                (like) => like.likes === true
                                                            ).length
                                                        }{" "}
                                                    </span>
                                                ) : (
                                                    <span className="px-1">
                                                        <i
                                                            class="fa-regular fa-thumbs-up px-1"
                                                            style={{
                                                                fontSize: "24px",
                                                                color: "#0000008f",
                                                                cursor:"pointer"
                                                            }}
                                                            onClick={() =>
                                                                handleLike(review._id, true)
                                                            }
                                                        ></i>{" "}
                                                        {
                                                            review.liked.filter(
                                                                (like) => like.likes === true
                                                            ).length
                                                        }{" "}
                                                    </span>
                                                )}
                                                <i
                                                    onClick={() => handleLike(review._id, false)}
                                                    class="fa-regular fa-thumbs-down px-2 my-2"
                                                    style={{
                                                        fontSize: "24px",
                                                        color: "#0000008f",
                                                        cursor:"pointer"

                                                    }}
                                                ></i>
                                            </div>

                                            <div className="">
                                                {review.reported.some(
                                                    (like) =>
                                                        like.name === userName &&
                                                        like.reports === true
                                                ) ? (
                                                    <span className="px-1 align-items-center d-flex">
                                                        <i
                                                            class="ri-flag-2-fill mx-2"
                                                            style={{
                                                                fontSize: "24px",
                                                                color: "#0000008f",
                                                                cursor:"pointer"
                                                            }}
                                                        // onClick={() =>
                                                        //   handleReport(latestReview._id, false)
                                                        // }
                                                        ></i>
                                                        Reported{" "}
                                                    </span>
                                                ) : (
                                                    <span className="px-1 align-items-center d-flex">
                                                        <i
                                                            class="ri-flag-2-line mx-2 "
                                                            style={{
                                                                fontSize: "24px",
                                                                color: "#0000008f",
                                                                cursor:"pointer"
                                                            }}
                                                            onClick={() =>
                                                                handleReport(review._id, true)
                                                            }
                                                        ></i>
                                                        Report{" "}
                                                    </span>
                                                )}

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default GiveReview;